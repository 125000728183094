import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const URL = 'wss://team.harvok.com.au/api/vehicle_update/notification/'

export const Socket = createContext(null)

const SocketProvider = ({ children }) => {

    const token = useSelector((state) => state.user.token)
    // const[socket,setSocket] = useState(null)
    // const[isOpen,setIsOpen] = useState(false)

    // const close = useCallback(()=>{
    //     if(socket?.readState === WebSocket.OPEN) socket?.close(1000,'Closing Connection')
    //     setSocket(null)
    // },[socket])

    // const handleOpen = useCallback(() =>{
    //     setIsOpen(true)
    // },[])
    // const handleClose = useCallback(() =>{
    //     setIsOpen(false)
    // },[])
    // const handleError = useCallback(() =>{
    //     setIsOpen(false)
    // },[])

    // useEffect(()=>{
    //     let wb = null;
    //     if(token && !isOpen){
    //         wb = new window.WebSocket(URL)
    //         wb.addEventListener('close',handleClose)
    //         wb.addEventListener('open',handleOpen)
    //         wb.addEventListener('error',handleError)
    //         setSocket(()=>wb)
    //     }
    //     return(()=>{
    //         close()
    //         if(wb){
    //             wb.addEventListener('close',handleClose)
    //             wb.addEventListener('open',handleOpen)
    //             wb.addEventListener('error',handleError)
    //         }
    //     })
    // },[token,isOpen])
    const [isReady, setIsReady] = useState(false)
    const [val, setVal] = useState(null)

    const ws = useRef(null)

    useEffect(() => {
        if(token){
            console.log('inisde if wb initialization')
            let  socket = new WebSocket(URL)
            socket.onopen = () => setIsReady(true)
            socket.onclose = () => setIsReady(false)
            socket.onmessage = (event) => {
                setVal(event.data)
                // setTimeout(()=>{ setVal(null)},1000 * 3)
            }
            ws.current = socket
        }else{
            console.log('inisde else wb initialization')
        }
        return () => {
            if(ws.current) ws.current.close()
        }
    }, [token])

    const close = (code=1000,msg='User disconnected') =>{
        if(ws.current) {
            ws.current.close(code,msg)
            ws.current = null
        }
    }

    const clearMsg = () => { setVal(null) }

    const ret = {isReady, val, send : ws.current?.send?.bind(ws.current), close, clearMsg}

    return (
        <Socket.Provider value={ret}>
            {children}
        </Socket.Provider>
    )
}


export default SocketProvider;