import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import axios from 'axios'
// import ProtectLayer from './views/pages/protect/ProtectLayer'
import { useSelector } from 'react-redux'
// import useWebSocket from './components/utils/useWebSocket'
// import { useDispatch, useSelector } from 'react-redux'
// import { initializeConnection, closeConnection } from './Slices/socket'

axios.defaults.baseURL = 'https://team.harvok.com.au'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Forgotpassword = React.lazy(() => import('./views/pages/forgotPassword/Forgotpassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Terms = React.lazy(() => import('./views/pages/website/Terms'))
const Cookie = React.lazy(() => import('./views/pages/website/Cookie'))

function App() {

  // const canAccess =  useSelector((state) => state.user.canAccess)

//   const handleIdle = () => {
//     dispatch(removeUser())
//     localStorage.clear()
//     navigate('/login')
// }


  return (
   <Router>
   <Suspense fallback={loading}>
     <Routes>
      <Route exact path="/login" name="Login Page" element={<Login />} />
      <Route exact path="/register" name="Register Page" element={<Register />} />
      <Route exact path="/forgot-password" name="Register Page" element={<Forgotpassword />} />
      <Route exact path="/terms-and-conditions" name="Terms and Conditions Page" element={<Terms />} />
      <Route exact path="/cookie-policy" name="Cookie Policy Page" element={<Cookie />} />
      <Route exact path="/500" name="Page 500" element={<Page500 />} />
      <Route path="/*" name="Home" element={<DefaultLayout />} />
     
     <Route path="*" name="Page 404" element={<Page404 />} />
     </Routes>
   </Suspense>
 </Router>
 )

}

export default App
